import { TDonutItem, TDonutPhase } from '../../lifecycle/interface/donut-chart.type'
import { ILifecycleFlatLeafItem, ITransformedLifecycleLeafItem } from '../../lifecycle/interface/transformed-lifecycle-leaf-item'
import { IImpact, ILifecycle, IPhase } from '../../model'
import { ImpactType } from '../enum/impact'
import { transformLeafItems, transformToMultiLeafItems, TTransformMultiLeafItem } from './transform'

export type TTransformMultiLifecycleLeafItem = {
  id?: string,
  phaseName?: string,
  commonPhase?: boolean,
  transformedMultiLeafItem?: TTransformMultiLeafItem[]
}

export type TFlatLifecycleLeafItem = TTransformMultiLeafItem & { phaseName?: string }

export const transformToLifecycleFlatLeafItems = (
  lifecycleLeafItems: ITransformedLifecycleLeafItem[],
  withPhaseDetail: boolean = true
): ILifecycleFlatLeafItem[] => {
  const lifecycleFlatLeafItems: ILifecycleFlatLeafItem[] = []
  lifecycleLeafItems.forEach((lifecycleLeafItem, index) => {
    const { id: phaseId, phaseName, transformedLeafItems = [] } = lifecycleLeafItem
    transformedLeafItems.forEach(transformedLeafItem => {
      const rowId = `${phaseId}_${transformedLeafItem.id}`
      lifecycleFlatLeafItems.push(withPhaseDetail ? {
        order: index, rowId, phaseId, phaseName, ...transformedLeafItem
      } : transformedLeafItem)
    })
  })

  return lifecycleFlatLeafItems
}

export const transformToDonutChartData = (phases: IPhase[] = []): TDonutPhase[] => phases
  .filter(phase => {
    const { totalImpact } = phase?.inventory || {}
    return totalImpact?.amount && Math.abs(parseFloat(totalImpact.amount)) !== 0
  })
  .map((phase, index) => {
    const { name, inventory } = phase
    const { leafInventoryItems = [], totalImpact } = inventory || {}
    const children: TDonutItem[] = leafInventoryItems
      .filter(leafInventoryItem => {
        const impactAmount = leafInventoryItem?.impact?.amount
        return impactAmount && Math.abs(parseFloat(impactAmount)) !== 0
      })
      .sort((a, b) => {
        const aImpactAmount = a?.impact?.amount
        const bImpactAmount = b?.impact?.amount
        if (!aImpactAmount || !bImpactAmount) return 0
        return parseFloat(bImpactAmount) - parseFloat(aImpactAmount)
      })
      .map((leafInventoryItem, itemIndex) => {
        const impactAmount = leafInventoryItem?.impact?.amount ? parseFloat(leafInventoryItem?.impact?.amount) : 0
        return {
          id: leafInventoryItem?.product?.id,
          name: leafInventoryItem?.product?.name,
          order: itemIndex,
          impactAmount: Math.abs(impactAmount),
          data: leafInventoryItem,
          impactType: impactAmount > 0 ? ImpactType.Positive : ImpactType.Negative
        }
      })
      .reduce((uniqueItemList:TDonutItem[], leafInventoryItem:TDonutItem) => {
        const existingItem:TDonutItem | undefined = uniqueItemList.find((item:TDonutItem) => item.id === leafInventoryItem.id)
        if (existingItem) {
          existingItem.impactAmount += leafInventoryItem.impactAmount
          if (existingItem.data?.amount && leafInventoryItem?.data?.amount) {
            existingItem.data.amount += leafInventoryItem.data.amount
          }
        } else {
          uniqueItemList.push({ ...leafInventoryItem })
        }

        return uniqueItemList
      }, [])
    const totalImpactAmount = totalImpact?.amount ? parseFloat(totalImpact.amount) : 0
    return {
      name,
      order: index,
      totalImpactAmount: Math.abs(totalImpactAmount),
      totalImpactUnit: totalImpact?.unit,
      totalImpactType: totalImpactAmount > 0 ? ImpactType.Positive : ImpactType.Negative,
      children
    }
  })

export const transformLifecycleLeafItems = (
  lifecycle: ILifecycle = {},
  lifecycleTotalImpact?: IImpact
) => {
  const { phases = [] } = lifecycle

  return phases?.map((phase: IPhase) => {
    const {
      id,
      name: phaseName,
      inventory: {
        leafInventoryItems = [],
      } = {}
    } = phase
    const transformedLeafItems = transformLeafItems({ leafInventoryItems, totalImpactAmount: lifecycleTotalImpact?.amount })
    return { id, phaseName, transformedLeafItems }
  }) as ITransformedLifecycleLeafItem[]
}

export const transformToMultiLifecycleLeafItems = (
  primaryLifecycleLeafItems: ITransformedLifecycleLeafItem[],
  secondaryLifecycleLeafItems: ITransformedLifecycleLeafItem[]
): TTransformMultiLifecycleLeafItem[] => {
  const primaryImpacts = primaryLifecycleLeafItems.flatMap(
    ({ transformedLeafItems = [] }) => transformedLeafItems.map(item => item?.impactAmount)
  ) || [] as number[];
  const secondaryImpacts = secondaryLifecycleLeafItems.flatMap(
    ({ transformedLeafItems = [] }) => transformedLeafItems.map(item => item?.impactAmount)
  ) || [] as number[];
  const allImpacts = [ ...primaryImpacts, ...secondaryImpacts ]
    .filter(value => value !== null && value !== undefined)
    .map(value => parseFloat(value || ''))
  const maxLifecycleComparisonImpact = Math.max(...allImpacts)

  const commonPhases = primaryLifecycleLeafItems
    .filter(pItem => secondaryLifecycleLeafItems.some(sItem => (sItem.phaseName === pItem.phaseName)))
    .map(primaryLifecycleLeafItem => {
      const {
        id: primaryId,
        phaseName: primaryPhase,
        transformedLeafItems: primaryTransformedLeafItems
      } = primaryLifecycleLeafItem

      const secondaryLifecycleLeafItem = secondaryLifecycleLeafItems
        .find(item => item.phaseName === primaryPhase) || {}

      const {
        transformedLeafItems: secondaryTransformedLeafItems
      } = secondaryLifecycleLeafItem

      const transformedMultiLeafItem = transformToMultiLeafItems(
        primaryTransformedLeafItems,
        secondaryTransformedLeafItems,
        maxLifecycleComparisonImpact
      )

      return {
        id: primaryId,
        phaseName: primaryPhase,
        commonPhase: true,
        transformedMultiLeafItem
      }
    })

  const onlyPrimaryPhases = primaryLifecycleLeafItems
    .filter(pItem => !secondaryLifecycleLeafItems.some(sItem => (sItem.phaseName === pItem.phaseName)))
    .map(primaryLifecycleLeafItem => {
      const { id, phaseName, transformedLeafItems } = primaryLifecycleLeafItem
      const transformedMultiLeafItem = transformToMultiLeafItems(transformedLeafItems, [], maxLifecycleComparisonImpact)
      return {
        id, phaseName, commonPhase: false, transformedMultiLeafItem
      }
    })

  const onlySecondaryPhases = secondaryLifecycleLeafItems
    .filter(pItem => !primaryLifecycleLeafItems.some(sItem => (sItem.phaseName === pItem.phaseName)))
    .map(secondaryLifecycleLeafItem => {
      const { id, phaseName, transformedLeafItems } = secondaryLifecycleLeafItem
      const transformedMultiLeafItem = transformToMultiLeafItems([], transformedLeafItems, maxLifecycleComparisonImpact)
      return {
        id, phaseName, commonPhase: false, transformedMultiLeafItem
      }
    })

  return [
    ...commonPhases,
    ...onlyPrimaryPhases,
    ...onlySecondaryPhases
  ]
}
