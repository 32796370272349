import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'
import { LifecycleInsightContainer } from '../../container/lifecycle-insight.container'
import LifecycleDetailContext from '../../../lifecycle/provider/context/lifecycle-detail.context'
import { TLifecycleDetailContext } from '../../../lifecycle/interface/lifecycle-detail-context.type'
import { transformToMultiLifecycleLeafItems } from '../../../shared/util/transform-lifecycle'
import { LifecycleInsightWrapperLayout } from '../../layout/lifecycle-insight-wrapper.layout'
import { TableComponent } from './table/table.component'
import { LifecycleDetailDialogComponent } from '../../../lifecycle/component/dialog/lifecycle-detail-dialog.component'
import { DonutPanelComponent } from './chart/donut-panel.component'
import { SplitScreenLayout } from '../../../shared/layout/split-screen.layout'
import { ComparisonEntity } from '../../enum/entity'

export const InsightComponent = () => {
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { selectedInventoryViewType, showLifecycleDetailPanelOnly = false } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    primaryLifecycleLeafItems,
    secondaryLifecycleLeafItems,
    primaryLeafItemsRefetch,
    secondaryLeafItemsRefetch,
    loadingLeafItems,
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext)
  const isInsightViewType = (viewType: InventoryViewType) => viewType === selectedInventoryViewType
  useEffect(() => {
    if (space) {
      reloadLeafItems()
    }
  }, [ space ])

  useEffect(() => {
    if (!loadingLeafItems && primaryLifecycleLeafItems && secondaryLifecycleLeafItems) {
      const multiLifecycleLeafItems = transformToMultiLifecycleLeafItems(primaryLifecycleLeafItems, secondaryLifecycleLeafItems)
      updateAnalysis({ multiLifecycleLeafItems })
    }
  }, [ primaryLifecycleLeafItems, secondaryLifecycleLeafItems, loadingLeafItems ])

  const reloadLeafItems = () => {
    primaryLeafItemsRefetch && primaryLeafItemsRefetch()
    secondaryLeafItemsRefetch && secondaryLeafItemsRefetch()
  }

  return (
    <>
      <LifecycleInsightContainer>
        <>
          { isInsightViewType(InventoryViewType.FlatView) && (
            <LifecycleInsightWrapperLayout>
              <TableComponent />
            </LifecycleInsightWrapperLayout>
          ) }

          { isInsightViewType(InventoryViewType.Sunburst) && (
            <LifecycleInsightWrapperLayout>
              <div className="flex w-full">
                <SplitScreenLayout className="flex-wrap" firstClassName="w-full lg:w-6 pr-3" secondClassName="w-full pt-3 lg:w-6 pl-3 lg:pt-0">
                  <DonutPanelComponent />
                  <DonutPanelComponent entity={ComparisonEntity.Secondary} />
                </SplitScreenLayout>
              </div>
            </LifecycleInsightWrapperLayout>
          ) }

          { !showLifecycleDetailPanelOnly && <LifecycleDetailDialogComponent reload={() => {}} /> }
        </>
      </LifecycleInsightContainer>
    </>
  )
}
