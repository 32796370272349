import React, { useRef } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Menu } from 'primereact/menu'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useTranslation } from 'react-i18next'
import { IProduct } from '../../../model'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'

const GlobalStyle = createGlobalStyle`
  .select-scenario {
    padding: 0.5rem 0 !important;

    .p-menuitem {
      padding: 0;
    }

    .p-menuitem-link {
      padding: 0.5rem 1rem;
    }
  }
`
enum Action {
  NoAction,
  ChooseScenario,
  CreateScenario
}

type CreateScenarioComponentProps = {
  copyingProduct?: boolean
  showCreateScenarioDialog: boolean,
  productWithImpact: IProduct,
  closeCreateScenarioDialog: () => void,
  handleCreateScenario: () => void
  handleChooseScenario: (param: string) => void
}
export const CreateScenarioComponent = ({
  copyingProduct = false,
  productWithImpact,
  showCreateScenarioDialog,
  closeCreateScenarioDialog,
  handleCreateScenario,
  handleChooseScenario
}: CreateScenarioComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const menu = useRef<Menu>(null)
  const { scenarios = [] } = productWithImpact || {}

  const executeCommand = (event: any) => {
    const { id, action } = event.item
    switch (action) {
    case Action.ChooseScenario:
      handleChooseScenario(id)
      break
    case Action.CreateScenario:
      handleCreateScenario()
      break
    default:
      break
    }
  }

  const scenariosItems: any[] = (scenarios.length !== 0)
    ? scenarios.map(scenario => ({
      label: scenario.name,
      id: scenario.id,
      action: Action.ChooseScenario,
      command: executeCommand,
      template: MenuItemComponent
    })) : []

  scenariosItems.splice(
    scenariosItems.length,
    0,
    { separator: true },
    {
      icon: <FontAwesomeIcon data-testid="create-scenario-icon" icon={faPlus} className="text-sm" />,
      label: 'Create',
      action: Action.CreateScenario,
      command: executeCommand,
      template: MenuItemComponent
    }
  )

  return (
    <>
      <GlobalStyle />
      <Dialog
        className="w-30rem"
        data-testid="create-scenario-dialog"
        header={<span data-ac="create-scenario-dialog">{t('labels.scenario')}</span>}
        visible={showCreateScenarioDialog}
        draggable={false}
        dismissableMask
        onHide={closeCreateScenarioDialog}
      >
        <div className="w-full pb-6">
          <div className="flex w-full flex-column">
            <div className="flex flex-none">
              <label htmlFor="product">
                { t('form.label', { context: 'developScenario' }) }
              </label>
            </div>
            <div className="flex flex-none">
              <Menu model={scenariosItems} popup ref={menu} id="popup_menu" className="mt-1 w-24rem select-scenario" />

              <Button
                iconPos="right"
                label={t('form.placeholderChooseCreate', { context: 'scenario' })}
                icon="pi pi-chevron-down"
                data-testid="choose-create-scenario"
                data-cy="scenario-product-select"
                onClick={(event: any) => menu.current?.toggle(event)}
                className="p-button-outlined p-button-plain p-button-sm text-sm w-full text-left"
                aria-controls="popup_menu"
                aria-haspopup
              />
            </div>
          </div>

          <div className="col-12 pt-6 px-0 flex justify-content-end">
            <div className="flex-none">
              <Button
                data-testid="cancel-create-scenario"
                onClick={closeCreateScenarioDialog}
                label={t('actions.cancel')}
                iconPos="left"
                loading={copyingProduct}
                className="p-button-outlined p-button-plain justify-content-end"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
