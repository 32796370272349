import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

import { HeaderComponent } from '../component/header.component'
import { TProductDetailContext } from '../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../product/provider/context/product-detail.context'
import { TrackEventType } from '../../shared/enum/track-events'
import { trackEventInSegment } from '../../../utils/SegmentWrapper'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { ILifecycle, IProduct } from '../../model'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { EntityType } from '../../shared/enum/entity-type'
import { TaskbarComponent } from '../component/product/taskbar.component'

import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { ModelLayout } from './model.layout'
import { FixedComponent } from '../component/fixed.component'
import { InsightComponent } from '../component/product/insight.component'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { ProductDialogComponent } from '../../product/component/dialog/product-dialog.component'

const ProductWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;

  .body-wrapper {
    min-height: 28rem;
  }
`
export const ProductAnalysisLayout = () => {
  const navigate = useNavigate()
  const { productId = '' } = useParams()
  const {
    productInventory,
    selectedInventoryViewType
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const {
    showCreateComparisonDialog = false,
    addToBackToList = () => {},
    updateDashboard
  } = useContext<IDashboardContext>(DashboardContext)

  const {
    updateAnalysis,
    secondaryUpdateDetail,
    secondaryRootProductRefetch,
    secondaryInventoryItemsRefetch
  } = useContext<TAnalysisContext>(AnalysisContext)

  useEffect(() => {
    productInventory && updateDashboard({ collapsedNavigation: true })
  }, [ productInventory ])

  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    const activeTabKey = comparisonEntity.__typename === EntityType.LifeCycle ? 'tab-flat-view' : 'tab-tree-view'
    productInventory?.product?.name && addToBackToList(productInventory?.product?.name)
    if (comparisonEntity.__typename !== EntityType.LifeCycle) {
      const secondaryEntityId = comparisonEntity.id
      navigate(`/account/${account?.id}/workspace/${space?.slug}/product/${productId}/compare/?`
        + `activeTabKey=${InventoryViewType.Model}&type=${comparisonEntity.__typename}&id=${secondaryEntityId}`)
      updateAnalysis({ secondaryEntityId })
    } else {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/product/${productId}/inventory/?`
        + `activeTabKey=${activeTabKey}&compareEntityType=${comparisonEntity.__typename}&compareEntityId=${comparisonEntity.id}`)
    }
  }

  const reloadInventoryItems = () => {
    secondaryRootProductRefetch && secondaryRootProductRefetch()
    secondaryInventoryItemsRefetch && secondaryInventoryItemsRefetch()
    secondaryUpdateDetail && secondaryUpdateDetail({ hasInventoryChanged: true })
  }

  return (
    <ProductWrapper
      className="relative w-full h-screen flex-column product-detail-wrapper"
    >
      { productInventory && (
        <div
          data-testid="analysis-detail-wrapper"
          className="absolute top-0 left-0 flex w-full h-full flex-column"
        >
          <div data-testid="analysis-detail-header" className="flex flex-column w-full h-8rem mb-3 px-6 py-2">
            <HeaderComponent />
          </div>
          <div data-testid="analysis-detail-taskbar" className="flex w-full h-3rem px-6 py-2">
            <TaskbarComponent />
          </div>
          <div data-testid="analysis-detail-body" className="flex w-full px-6 pt-3 flex-column body-wrapper">
            { isModelViewType() && <ModelLayout /> }
            { !isModelViewType() && <InsightComponent /> }
          </div>
        </div>
      ) }

      <ProductDialogComponent reloadInventoryItems={reloadInventoryItems} />

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} /> }

      { isModelViewType() && <FixedComponent /> }
    </ProductWrapper>
  )
}
