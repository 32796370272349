import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { GettingStartedChecklistComponent } from '../component/getting-started/getting-started-checklist.component'
import { GettingStartedSidebarComponent } from '../component/getting-started/getting-started-sidebar.component'

const GettingStartedWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`

const AccordionWrapper = styled.div`
  width: calc(100% - 20rem);
`

export const GettingStartedLayout = () => {
  const [ , trackPageInSegment ] = getSegmentTrack()
  const { t } = useTranslation([ 'workspace' ])

  useEffect(() => {
    trackPageInSegment(`Page loaded: ${TrackEventType.VIEWED_GETTING_STARTED}`, TrackEventType.VIEWED_GETTING_STARTED)
  }, [])

  return (
    <GettingStartedWrapper className="flex w-full h-screen flex-column">
      <div className="flex flex-none w-full h-6rem text-gray-500 font-medium px-6 pt-6" data-cy="page-header-container">
        {t('labels.gettingStarted')}
      </div>
      <div className="flex w-full text-gray-700 font-semibold text-2xl px-6">
        {t('labels.gettingStarted', { context: 'Header' })}
      </div>
      <div className="flex w-full h-full px-6 pb-3 gap-5">
        <AccordionWrapper className="flex h-full">
          <GettingStartedChecklistComponent />
        </AccordionWrapper>

        <div className="flex flex-column w-20rem h-full">
          <div className="flex-none w-full pt-3">
            <GettingStartedSidebarComponent />
          </div>
        </div>
      </div>
    </GettingStartedWrapper>
  )
}
