import React, {
  useContext, useEffect, useMemo, useRef
} from 'react'
import {
  useSearchParams
} from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'

import { useTranslation } from 'react-i18next'
import { CustomImpactComponent, TCustomImpactFormItem } from '../component/custom-impact.component'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IImpactCategory, IProduct } from '../../model'
import { isValid } from '../../shared/util/tools'
import { CHANGE_PRODUCT_CUSTOM_IMPACTS } from '../../graphql/mutation'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'
import { displayGraphqlErrors } from '../../shared/util/error'
import { impactCategoriesVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { CustomImpact } from '../../../__generated__/graphql'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { useScenarioAction } from '../../analysis/hook/use-scenario-action'
import { ScenarioAction } from '../../analysis/enum/scenario-action'

export const CustomImpactContainer = () => {
  const { t } = useTranslation([ 'impact-dataset' ])

  const { space: selectedSpace } = useReactiveVar(selectedWorkspaceVar) || {}
  const impactCategories: IImpactCategory[] = useReactiveVar(impactCategoriesVar) || []
  const { selectedEntity, updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const toast = useRef<Toast>(null)
  const { navigateBackToSource } = useDatasetNavigate()
  const [ searchParams ] = useSearchParams()
  const scenarioProductID = searchParams.get('scenarioProductID')
  const scenarioSelectedKey = searchParams.get('scenarioSelectedKey')
  const handleScenarioAction = useScenarioAction(scenarioProductID)

  const { customImpacts = [] } = selectedEntity as IProduct || {}

  const defaultCustomImpacts: TCustomImpactFormItem[] = useMemo(() => (
    impactCategories.map(impactCategory => {
      const { amount = '0' } = customImpacts.find(customImpact => customImpact.impactCategoryID === impactCategory.id) || {}
      return {
        amount, id: impactCategory.id, name: impactCategory.name, unit: impactCategory.unit
      }
    }) as TCustomImpactFormItem[]
  ), [ impactCategories, customImpacts ])

  const [
    changeProductCustomImpacts,
    {
      error: failedChangingCustomImpact,
      data: changedProductCustomImpactsData,
      loading: changingProductCustomImpacts
    }
  ] = useMutation(CHANGE_PRODUCT_CUSTOM_IMPACTS)

  const { changeProductCustomImpacts: changedProductCustomImpacts } = changedProductCustomImpactsData || {}
  useEffect(() => {
    if (!changingProductCustomImpacts && (
      changedProductCustomImpacts?.id
      || failedChangingCustomImpact
    )) {
      changeProductCustomImpactsCallback(failedChangingCustomImpact, changedProductCustomImpactsData)
    }
  }, [ changingProductCustomImpacts, changedProductCustomImpacts, failedChangingCustomImpact ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const changeProductCustomImpactsCallback = (error?: any, data?: any) => {
    try {
      if (error) {
        throw error
      } else if (data) {
        // TO DO : Move or Discard the call for updating impact setting
        let selectedEntity = null
        if (scenarioSelectedKey && data.performScenarioAction) {
          selectedEntity = data.performScenarioAction
        } else if (data.changeProductCustomImpacts) {
          selectedEntity = data.changeProductCustomImpacts
        }
        selectedEntity && updateDashboard({ selectedEntity })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.changeProductCustomImpacts', { context: 'success' }),
          life: 1000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.changeProductCustomImpacts', { context: 'error' }), error?.graphQLErrors)
    }
  }

  const handleChangeCustomImpact = (customImpactFormItems: TCustomImpactFormItem[] = []) => {
    const impacts: CustomImpact[] = customImpactFormItems
      .filter(customImpactFormItem => isValid(customImpactFormItem.id) && isValid(customImpactFormItem.amount))
      .map(customImpactFormItem => ({
        categoryID: customImpactFormItem.id || '',
        amount: customImpactFormItem.amount || ''
      })) || []

    if (!selectedEntity?.id || !selectedSpace?.impactMethod?.id || impacts.length === 0) return

    const variables = {
      productID: selectedEntity?.id,
      impacts,
      impactMethodID: selectedSpace?.impactMethod?.id,
      excludeLT: selectedSpace?.excludeLT as boolean,
    }

    trackEventInSegment(customImpacts?.length > 0 ? TrackEventType.CHANGED_CUSTOM_IMPACT : TrackEventType.ADDED_CUSTOM_IMPACT)
    if (handleScenarioAction && scenarioSelectedKey) {
      handleScenarioAction(ScenarioAction.ChangeProductCustomImpacts, variables, scenarioSelectedKey, changeProductCustomImpactsCallback)
    } else {
      changeProductCustomImpacts({ variables })
    }
  }

  return (
    <div className="flex bg-white h-full w-full justify-content-center align-items-center">

      <Toast data-testid="custom-impact-status" ref={toast} position="top-right" onHide={navigateBackToSource} />

      { defaultCustomImpacts?.length > 0
        && (
          <CustomImpactComponent
            changingProductCustomImpacts={changingProductCustomImpacts}
            defaultCustomImpacts={defaultCustomImpacts}
            handleChangeCustomImpact={handleChangeCustomImpact}
          />
        )}
    </div>
  )
}
