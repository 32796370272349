import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'

import { createGlobalStyle } from 'styled-components'
import { InputText } from 'primereact/inputtext'
import { IControllerRender } from '../interface/react-form-hook'
import { IDashboardContext } from '../interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { PRODUCT_WITH_IMPACT, REFERENCE_PROPERTIES } from '../../graphql/query'
import { CHANGE_PRODUCT_PROPERTY } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../util/error'
import { IProduct } from '../../model/product'

const GlobalStyle = createGlobalStyle`
  .w-34rem {
    width: 34rem;
  }
`

type UpdateProductPropertyContainerProps = {
  onUpdateCallback?: Function
}

export const UpdateProductPropertyContainer = ({
  onUpdateCallback = () => {}
}:UpdateProductPropertyContainerProps) => {
  const { t } = useTranslation([ 'common', 'impact-dataset', 'product' ])
  const toast = useRef<Toast>(null)
  const defaultValues = { inputAmount: '', inputUnit: '' }
  const [ unitOptions, setUnitOptions ] = useState<any>(null)
  const [ updatedProduct, setUpdatedProduct ] = useState<IProduct>({})
  const [ selectedUnitLabel, setSelectedUnitLabel ] = useState<string>('')
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  const { selectedEntity, showUpdatePropertyDialog, updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  const {
    referenceProduct,
    id: productID,
    unit: productUnit,
    name: productName
  } = updatedProduct || {}
  const {
    amount: referenceAmount,
    referenceProperty,
    referenceUnit
  } = referenceProduct || {}

  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    skip: !selectedEntity?.id,
    variables: { id: selectedEntity?.id || '' },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: loadingReferenceProperties,
    data: referencePropertiesData,
  } = useQuery(REFERENCE_PROPERTIES, {
    skip: unitOptions,
    fetchPolicy: 'no-cache'
  })

  const [
    changeProductProperty,
    {
      error: failedChangingProductProperty,
      data: changeProductPropertyData,
      loading: changingProductProperty
    }
  ] = useMutation(CHANGE_PRODUCT_PROPERTY)

  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      const { product = {} } = productWithImpactData?.productWithImpact || {}
      setUpdatedProduct(product)
    }
  }, [ loadingProductWithImpact, productWithImpactData ])

  const changedProductProperty = changeProductPropertyData?.changeProductProperty
  useEffect(() => {
    if (!changingProductProperty && (!!changedProductProperty || failedChangingProductProperty)) {
      changeProductPropertyCallback()
    }
  }, [ changingProductProperty, !!changedProductProperty, failedChangingProductProperty ])

  useEffect(() => {
    const { referenceProperties } = referencePropertiesData || {}

    if (!loadingReferenceProperties && referenceProperties && referenceProperty?.id) {
      const { referenceUnits = [] } = referenceProperties.find(property => property.id === referenceProperty?.id) || {}
      const unitOptions = referenceUnits.map(unit => ({
        label: unit.name,
        value: unit.id
      }))
      setUnitOptions(unitOptions)
      setSelectedUnitLabel(referenceUnit?.name || '')
      reset({
        inputAmount: String(parseFloat(referenceAmount || '')),
        inputUnit: referenceUnit?.id || ''
      })
    }
  }, [ loadingReferenceProperties, referencePropertiesData, updatedProduct ])

  const showSuccessMessage = () => {
    toast?.current?.show({
      severity: 'success',
      summary: t('messages.successSummary'),
      detail: t('messages.success', { context: 'changeProductProperty', ns: 'product' }),
      life: 1000
    })
  }

  const changeProductPropertyCallback = () => {
    try {
      if (failedChangingProductProperty) {
        throw failedChangingProductProperty
      } else if (changedProductProperty) {
        showSuccessMessage()
        onUpdateCallback()
        onHide()
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'changeImpactProduct', ns: 'product' }), error?.graphQLErrors)
    }
  }

  const onSubmit = (data: any) => {
    const { inputAmount, inputUnit } = data

    if (String(inputAmount) === String(parseFloat(referenceAmount || '')) && inputUnit === referenceUnit?.id) {
      showSuccessMessage()
      return
    }

    if (referenceProduct?.referenceProperty?.id && productID) {
      changeProductProperty({
        variables: {
          productID,
          referencePropertyID: referenceProduct?.referenceProperty?.id,
          conversionFactor: String(parseFloat(inputAmount)),
          referenceUnitID: inputUnit
        }
      })
    }
  }

  const onHide = () => {
    reset()
    updateDashboard({ showUpdatePropertyDialog: false, selectedEntity: null })
  }

  const onUnitChange = (event: any, field:any) => {
    const unitOption = unitOptions.find((unit:any) => unit.value === event.value)

    setSelectedUnitLabel(unitOption?.label)
    field.onChange(event.value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === '-') {
      event.preventDefault()
    }
    if (event.key === 'Escape') {
      reset()
      onHide()
    }
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const getErrorMessage = (context: string): string => t('form.errorMessage', { context })

  return (
    <>
      <GlobalStyle />
      <Toast data-testid="updated-product-property" ref={toast} position="top-right" onHide={onHide} />
      <Dialog
        className="w-34rem"
        data-testid="update-property-dialog"
        header={t('labels.referenceProperty', { context: 'title', ns: 'impact-dataset' })}
        visible={showUpdatePropertyDialog}
        draggable={false}
        onHide={onHide}
      >
        <div className="w-full pb-6" data-testid="update-product-property-dialog">
          <div>{ t('labels.referenceProperty', { context: 'modalInfo', propertyName: referenceProperty?.name, ns: 'impact-dataset' }) }</div>
          <div className="font-semibold pt-3">{t('labels.referenceProperty', {
            context: 'amountLabel',
            propertyName: referenceProperty?.name,
            productAmount: 1,
            productUnit,
            productName,
            ns: 'impact-dataset'
          })}
          </div>

          {referenceProduct?.id && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid">
                <div className="col-fixed w-17rem pr-2">
                  <label htmlFor="inputAmount" className="text-sm">
                    {t('labels.amount')}
                  </label>
                  <Controller
                    name="inputAmount"
                    control={control}
                    rules={{ required: getErrorMessage('amount') }}
                    render={({ field, fieldState }: IControllerRender) => (
                      <InputText
                        autoFocus
                        id={field.name}
                        {...field}
                        placeholder={t('labels.referenceProperty', {
                          context: 'amountPlaceholder', unit: selectedUnitLabel, ns: 'impact-dataset'
                        })}
                        lang="en-IN"
                        type="number"
                        className={classNames('w-full', { 'p-invalid': fieldState.error })}
                        onKeyDown={onKeyDown}
                        data-testid="update-product-property-amountInput"
                      />
                    )}
                  />
                  {
                    errors?.inputAmount
            && <small className="p-error">{ errors?.inputAmount.message }</small>
                  }
                </div>
                <div className="col">
                  <label htmlFor="inputUnit" className="text-sm">
                    { t('labels.unit') }
                  </label>
                  <Controller
                    name="inputUnit"
                    control={control}
                    render={({ field, fieldState }: IControllerRender) => (
                      <Dropdown
                        id={field.name}
                        value={field.value}
                        placeholder={t('labels.unit')}
                        className={classNames('w-full', { 'p-invalid': fieldState.error })}
                        onChange={(event: any) => onUnitChange(event, field)}
                        options={unitOptions}
                        data-testid="update-product-property-unitDropdown"
                      />
                    )}
                  />
                  {
                    errors?.inputUnit
            && <small className="p-error">{ errors?.inputUnit.message }</small>
                  }
                </div>
                <div className="col-12 pt-4 px-0 flex justify-content-end gap-2">
                  <Button
                    type="button"
                    onClick={onHide}
                    label={t('actions.cancel')}
                    iconPos="left"
                    loading={changingProductProperty}
                    className="p-button-outlined p-button-plain justify-content-end"
                    data-testid="update-product-property-cancel"
                  />

                  <Button
                    type="submit"
                    label={t('actions.save')}
                    iconPos="left"
                    loading={changingProductProperty}
                    className="p-button-primary"
                    data-testid="update-product-property-submit"
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </Dialog>
    </>
  )
}
