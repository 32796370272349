import React, { useContext } from 'react'
import { Tag } from 'primereact/tag'
import { classNames } from 'primereact/utils'
import { Tooltip } from 'primereact/tooltip'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ComparisonEntity } from '../../../enum/entity'
import { TooltipOverflowContent, useRefOverflow } from '../../../../shared/hook/use-ref-overflow'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { store } from '../../../../../configureStore'
import FlagsSelector from '../../../../../redux/selectors/flags.selector'
import { setIsDetailPanelReadonlyV1Action, setIsDetailsPanelOpenAction } from '../../../../../redux/actions/flags.actions'
import { TLifecycleDetailContext } from '../../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../../lifecycle/provider/context/lifecycle-detail.context'
import { LifecycleSelector } from '../../../../../redux/selectors/lifecycle.selector'
import { setSelectedLifecycleAction } from '../../../../../redux/actions/lifecycle.actions'
import { clearUiWhenDetailPanelCloseAction } from '../../../../../redux/actions/clear.actions'
import { setSelectedProductAction } from '../../../../../redux/actions/global.actions'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../../graphql/cache'
import { Navigation } from '../../../../shared/enum'

interface ModelHeaderProps {
  readonly entity?: ComparisonEntity
}

const ModelHeader = styled.div`
  background: var(${(props: ModelHeaderProps) => (props.entity === ComparisonEntity.Primary ? '--gray-50' : '--blue-50')});

  .tree-wrapper-title {
    cursor: pointer;
    max-width: calc(100% - 8rem);
    &:hover {
      color: var(--primary-500) !important;
    }
  }

  .open-lifecycle:hover {
    color: var(--primary-500) !important;
  }
`
export const ModelHeaderComponent = ({
  entity = ComparisonEntity.Primary
}: ModelHeaderProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ entityRef, hasEntityOverflow, entityDom ] = useRefOverflow()
  const {
    primaryEntityName,
    secondaryEntityName,
    primaryEntityUnit,
    secondaryEntityUnit,
    primaryUpdateDetail = () => {},
    secondaryUpdateLifecycleProduct = () => {},
    primaryUpdateLifecycleProduct = () => {},
    analysisMode,
  } = useContext<TAnalysisContext>(AnalysisContext)
  const navigate = useNavigate()
  const { lifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const isPrimary = entity === ComparisonEntity.Primary
  const entityName = isPrimary ? primaryEntityName : secondaryEntityName
  const entityUnit = isPrimary ? primaryEntityUnit : secondaryEntityUnit
  const entityLabel = lifecycleDetail?.amount
    ? t('labels.unitOfEntityWithAmount', {
      amount: lifecycleDetail?.amount, unit: entityUnit, name: entityName, ns: 'common'
    })
    : t('labels.unitOfEntityName', { unit: entityUnit, name: entityName, ns: 'common' })

  const openDetailPanel = (event: any) => {
    // TODO : Added for compatibility with v0.9
    event.stopPropagation()

    const state = store.getState()
    const selectedLifecycleId = LifecycleSelector.lifecycleId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (lifecycleDetail?.id === selectedLifecycleId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    secondaryUpdateLifecycleProduct({ selectedKey: null, selectedProduct: null })
    primaryUpdateLifecycleProduct({ selectedKey: null, selectedProduct: null })

    primaryUpdateDetail({ showLifecycleDetailPanelOnly: true })

    store.dispatch(setSelectedProductAction(null))
    store.dispatch(setIsDetailPanelReadonlyV1Action(isPrimary))
    store.dispatch(setIsDetailsPanelOpenAction(true))
    store.dispatch(setSelectedLifecycleAction(lifecycleDetail))
  }

  const openLifecycle = () => {
    const lifecycleId = lifecycleDetail?.id
    account?.id && space?.slug && lifecycleId && navigate(generatePath(Navigation.LifeCycleDetail, {
      accountId: account.id,
      workspaceSlug: space.slug,
      lifecycleId
    }))
  }

  return (
    <ModelHeader entity={entity} className="flex w-full px-4 py-3 border-round-top-lg text-lg">

      { entityName && (
        <>
          <Tooltip target={entityDom} onBeforeShow={() => hasEntityOverflow} position="bottom">
            <TooltipOverflowContent>
              { (lifecycleDetail?.amount || entityUnit) ? entityLabel : entityName }
            </TooltipOverflowContent>
          </Tooltip>
          <div
            data-cy={`tree-header-${entity}-name`}
            ref={entityRef}
            data-testid="tree-wrapper-title"
            onClick={openDetailPanel}
            className="flex-none mr-2 font-medium text-base white-space-nowrap overflow-hidden text-overflow-ellipsis tree-wrapper-title"
          >
            { (lifecycleDetail?.amount || entityUnit) ? entityLabel : entityName }
          </div>
        </>
      ) }

      <div className="flex flex-grow-1 min-w-min">
        <Tag
          data-testid="tree-wrapper-tag"
          className={classNames('p-0 px-1 my-1 font-normal white-space-nowrap text-xs', {
            'bg-blue-100 text-primary-800 ': isPrimary,
            'bg-blue-500 text-white': !isPrimary
          })}
          value={isPrimary ? t('labels.original', { ns: 'common' }) : t('labels.product', { context: analysisMode.toLowerCase() })}
        />
      </div>
      <div className="flex w-2rem align-items-center justify-content-end">
        <FontAwesomeIcon
          onClick={openLifecycle}
          data-testid="stacked-impact-icon"
          icon={faArrowUpRightFromSquare}
          className="flex-none align-items-center text-grey-400 cursor-pointer open-lifecycle"
        />
      </div>
    </ModelHeader>
  )
}
