import React, {
  useContext, useEffect, useRef
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'

import { ImpactDatasetContainer } from '../container/impact-dataset.container'
import { ReferenceProductType } from '../enum/reference-product'

import { DefaultLandingComponent } from '../component/default-landing.component'
import { DatabaseSearchComponent } from '../component/database-search.component'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'

import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { CategoryType } from '../enum/category'

import { CategoryFilterContainer } from '../container/category-filter.container'
import { FilterChipsComponent } from '../component/filter/filter-chips.component'
import { SearchFieldComponent } from '../component/search-field.component'
import { SearchFieldHelpComponent } from '../component/search-field-help.component'
import { ElementaryFlowComponent } from '../component/elementary-flow.component'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct } from '../../model'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { PAGINATION } from '../../shared/constants'

interface ContentWrapperProps {
  readonly showSearchTips?: boolean
}

const SidebarWrapper = styled.div<ContentWrapperProps>`
  width: 23rem;
  box-shadow: ${(props: ContentWrapperProps) => (props.showSearchTips ? '-10px 0px 16px -12px rgba(0,0,0,0.2)' : '10px 0px 16px -12px rgba(0,0,0,0.2)')} ;
`

const ContentWrapper = styled.div<ContentWrapperProps>`
  width: ${(props: ContentWrapperProps) => (props.showSearchTips ? 'calc(100% - 47rem)' : 'calc(100% - 23rem)')};
`

export const SearchDatasetLayout = () => {
  const {
    query = '',
    locationQuery = '',
    currentPage = 1,
    totalItems = 0,
    showSearchTips = false,
    updateImpactDataset,
    referenceProducts = [],
    databaseFilterItems = [],
    categoryFilterItems = []
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { t } = useTranslation([ 'common', 'impact-dataset' ])
  const firstProduct = (currentPage - 1) * PAGINATION.PAGE_SIZE
  const scrollableContentRef = useRef<HTMLDivElement>(null)
  const { selectedEntity = {}, addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { datasetNavigate } = useDatasetNavigate()
  const { space: selectedWorkspace } = useReactiveVar(selectedWorkspaceVar) || {}
  const [ searchParams ] = useSearchParams()
  const selectedDatasetViewType = searchParams.get('activeTab') as ImpactDatasetViewType
  const { impactMethod } = selectedWorkspace || {}
  const noResultsFound = referenceProducts.length === 0

  const element = scrollableContentRef.current
  useEffect(() => {
    const updateHeaderStyle = () => {
      if (!element) return
      (element.scrollTop > 20)
        ? updateImpactDataset({ collapsedDatasetHeader: true })
        : updateImpactDataset({ collapsedDatasetHeader: false })
    }

    if (element) {
      element.addEventListener('scroll', updateHeaderStyle)
      window.addEventListener('resize', updateHeaderStyle)
    }

    return () => {
      element?.removeEventListener('scroll', updateHeaderStyle)
      window.removeEventListener('resize', updateHeaderStyle)
    }
  }, [ element ])

  const onCustomPageChange = (event: any) => {
    const variables = { currentPage: event.page + 1 }

    updateImpactDataset(variables)
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const isSelectedViewType = (datasetViewType: ImpactDatasetViewType) => datasetViewType === selectedDatasetViewType

  const hasMore = () => totalItems > PAGINATION.PAGE_SIZE

  const hasActiveCategoryFilter = () => categoryFilterItems.length > 1 || !!categoryFilterItems[0]?.selectedCategory

  const getProductAndCategoryTypes = (): [ReferenceProductType, CategoryType] => {
    if (isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)) {
      return [ ReferenceProductType.ProductSystem, CategoryType.Process ]
    }

    return [ ReferenceProductType.ElementaryFlow, CategoryType.Flow ]
  }

  const onRowClick = (id: string) => {
    const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct
    const selectedReferenceProduct = currentReferenceProduct?.id === id
      ? currentReferenceProduct
      : referenceProducts.find(referenceProduct => referenceProduct.id === id)

    if (selectedReferenceProduct) {
      const backLabel = isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)
        ? t('labels.databaseSearch', { ns: 'impact-dataset' })
        : t('labels.elementaryFlow', { ns: 'impact-dataset' })

      addToBackToList(backLabel)
      updateImpactDataset({ selectedReferenceProduct })
      datasetNavigate(selectedDatasetViewType, ImpactDatasetViewMode.Detail, false)
    }
  }

  const [ productReferenceType, categoryType ] = getProductAndCategoryTypes()
  const sideOrTopFilterEnabled = hasActiveCategoryFilter() || !!locationQuery || databaseFilterItems.length > 0
  const searchOrFilterEnabled = sideOrTopFilterEnabled || !!query

  const getDisplayContent = () => (
    <>
      <div className="flex w-full pt-3 pl-3 text-sm font-medium">
        { t('labels.results', { number: searchOrFilterEnabled ? totalItems : 0, ns: 'impact-dataset' }) }
      </div>

      <div className="flex w-full">
        { isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)
          ? <DatabaseSearchComponent showResults={searchOrFilterEnabled} onRowClick={onRowClick} />
          : <ElementaryFlowComponent showResults={searchOrFilterEnabled} onRowClick={onRowClick} /> }
      </div>
      {(!searchOrFilterEnabled || noResultsFound) && <div className="border-bottom-1 border-gray-100" />}
      {!searchOrFilterEnabled && (
        <div className="flex flex-grow-1 w-full">
          <DefaultLandingComponent />
        </div>
      )}
      {noResultsFound && searchOrFilterEnabled && (
        <div className="flex flex-column justify-content-center align-items-center w-full h-full" data-testid="no-datasets-search-results">
          <div className="font-semibold text-2xl mb-4">{ t('labels.result', { context: 'notFound', ns: 'impact-dataset' }) }</div>
          <FontAwesomeIcon icon={faSearch} className="text-primary-100" fontSize={96} />
        </div>
      )}
    </>
  )

  return (
    <>
      { impactMethod?.id && (
        <ImpactDatasetContainer type={productReferenceType} impactMethodId={impactMethod.id}>
          <div className="flex bg-white h-full overflow-hidden">

            <SidebarWrapper className="flex flex-column p-0 py-5 h-full">
              <CategoryFilterContainer type={categoryType} />
            </SidebarWrapper>

            <ContentWrapper showSearchTips={showSearchTips} ref={scrollableContentRef} className="flex flex-column p-4 h-full overflow-y-auto mt-1">

              <div className="flex w-full border-bottom-1 border-gray-100">
                <SearchFieldComponent />
              </div>

              { sideOrTopFilterEnabled && (
                <div className="flex w-full border-bottom-1 py-3 border-gray-100">
                  <FilterChipsComponent />
                </div>
              ) }

              { getDisplayContent() }

              <div className="flex w-full">
                { hasMore() && searchOrFilterEnabled
                    && <PagePaginatorComponent pageLinkSize={5} first={firstProduct} pageSize={PAGINATION.PAGE_SIZE} totalItems={totalItems} onPageChange={onCustomPageChange} /> }
              </div>

            </ContentWrapper>

            { showSearchTips && (
              <SidebarWrapper data-testid="search-tips-wrapper" showSearchTips={showSearchTips} className="flex flex-column p-0 pt-5 h-full ml-3">
                <SearchFieldHelpComponent />
              </SidebarWrapper>
            ) }
          </div>
        </ImpactDatasetContainer>
      )}
    </>
  )
}
