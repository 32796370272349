import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { EDatabaseSearchColumn } from '../enum/impact-dataset-column'

import { IDatabaseSearchResult } from '../interface/impact-dataset-result'
import { transformToDatabaseSearchResult } from '../util/transform'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableBodyTemplateComponent } from './table/table-body-template.component'
import { IProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TableHeaderComponent } from './table/table-header-template.component'
import { ReferenceProductType } from '../enum/reference-product'

const TableAnalysisWrapper = styled.div`
  .p-datatable {
    .p-datatable-tbody {
      tr {
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.05);

        &:hover {
          box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .p-datatable-emptymessage {
      display:none !important;
    }
  }
`
type TDatabaseSearchComponentProp = {
  onRowClick: (id: string) => void,
  showResults: boolean
}
export const DatabaseSearchComponent = ({
  onRowClick,
  showResults
}: TDatabaseSearchComponentProp) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { referenceProducts = [] } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)
  const { selectedEntity = {} } = useContext<IDashboardContext>(DashboardContext)

  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct || {}

  let dataToTransform = referenceProducts
  if (referenceProducts.length > 0 && currentReferenceProduct?.id) {
    dataToTransform = [
      currentReferenceProduct?.type === ReferenceProductType.ProductSystem ? currentReferenceProduct : {},
      ...referenceProducts.filter(referenceProduct => referenceProduct.id !== currentReferenceProduct?.id)
    ]
  }
  const impactDatasetResults = transformToDatabaseSearchResult(dataToTransform)

  const headerTemplate = (label: string, column?: EDatabaseSearchColumn) => <TableHeaderComponent label={label} sortDisabled column={column} />

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const bodyTemplate = (rowData: IDatabaseSearchResult, column: EDatabaseSearchColumn) => (
    <TableBodyTemplateComponent
      value={rowData}
      column={column}
      onRowClick={onRowClick}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      isMouseOver={rowData.id === mouseOverOn}
    />
  )

  const setRowColorForSelected = (impactDataset: IDatabaseSearchResult) => {
    const isSelected = currentReferenceProduct?.id === impactDataset.id
    return { 'bg-primary-50': isSelected }
  }

  return (
    <TableAnalysisWrapper
      className="flex w-full"
      data-testid="datasets-search-results-table"
    >
      <TableWrapperComponent>
        <DataTable
          value={showResults ? impactDatasetResults : []}
          rowClassName={setRowColorForSelected}
        >
          <Column
            field={EDatabaseSearchColumn.DatasetName}
            className="border-round-left-lg"
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.DatasetName)}
            header={headerTemplate(t('labels.datasetName'), EDatabaseSearchColumn.DatasetName)}
          />

          <Column
            field={EDatabaseSearchColumn.Geography}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Geography)}
            header={headerTemplate(t('labels.geography'), EDatabaseSearchColumn.Geography)}
          />

          <Column
            field={EDatabaseSearchColumn.Unit}
            style={{ width: '5rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Unit)}
            header={headerTemplate(t('labels.unit', { ns: 'common' }), EDatabaseSearchColumn.Unit)}
          />

          <Column
            field={EDatabaseSearchColumn.Database}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Database)}
            header={headerTemplate(t('labels.database'), EDatabaseSearchColumn.Database)}
          />

          <Column
            field={EDatabaseSearchColumn.Action}
            className="border-round-right-lg"
            style={{ width: '6.5rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Action)}
            header={() => <></>}
          />
        </DataTable>
      </TableWrapperComponent>
    </TableAnalysisWrapper>
  )
}
