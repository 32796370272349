import { useContext, useEffect, useState } from 'react'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { TProductDetailContext } from '../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../product/provider/context/product-detail.context'

export const useFixedSection = () => {
  const { primaryExpandedKey = [], secondaryExpandedKey = [] } = useContext<TAnalysisContext>(AnalysisContext)
  const {
    hasInventoryChanged,
    selectedInventoryViewType = InventoryViewType.Model
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const [ showFixedHeader, setShowFixedHeader ] = useState<boolean>(false)
  const [ showFixedFooter, setShowFixedFooter ] = useState<boolean>(false)

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model

  useEffect(() => {
    const modelWrapperDOM = document.querySelector('.product-detail-wrapper')
    const modalBodyDOM = document.querySelector('.model-layout-body')

    const updateHeaderFooterStyle = () => {
      if (!isModelViewType()) return

      if (modelWrapperDOM && modelWrapperDOM.scrollHeight > modelWrapperDOM.clientHeight + 2) {
        modalBodyDOM?.classList.add('pb-0')
        setShowFixedFooter(true)
      } else {
        modalBodyDOM?.classList.remove('pb-0')
        setShowFixedFooter(false)
      }

      if (modelWrapperDOM && modelWrapperDOM.scrollTop > 320) {
        setShowFixedHeader(true)
      } else {
        setShowFixedHeader(false)
      }
    }

    updateHeaderFooterStyle()

    if (modelWrapperDOM) {
      modelWrapperDOM.addEventListener('scroll', updateHeaderFooterStyle)
      window.addEventListener('resize', updateHeaderFooterStyle)
    }

    return () => {
      setShowFixedFooter(false)
      setShowFixedHeader(false)
      modelWrapperDOM?.removeEventListener('scroll', updateHeaderFooterStyle)
      window.removeEventListener('resize', updateHeaderFooterStyle)
    }
  }, [ secondaryExpandedKey.length, primaryExpandedKey.length, hasInventoryChanged ])

  return [ showFixedHeader, showFixedFooter ]
}
