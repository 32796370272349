import React, { useContext, useEffect, useRef } from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import styled from 'styled-components'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'

import { Messages } from 'primereact/messages'
import { selectedAccountVar } from '../../../graphql/cache'
import DashboardContext from '../context/dashboard.context'
import { Navigation, Policy } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { START_TRIAL } from '../../graphql/mutation/account'
import { displayGraphqlErrors } from '../../shared/util/error'
import { TrialExtendBanner } from '../component/trial-extend-banner'

type TrialExtendTimeWrapperProp = {
  showBanner: boolean
}

const TrialExtendTimeWrapper = styled.div<TrialExtendTimeWrapperProp>`
  width: 100%;
  height: ${(props: TrialExtendTimeWrapperProp) => (props.showBanner ? '4rem' : '0')};

  .p-message {
    border: none;
    margin: 0;
  }

  .p-message-summary,
  .p-message-wrapper {
    width: 100%;
  }

  .p-message-icon {
    display: none;
  }
`
export const TrialExtendTimeContainer = () => {
  const {
    account: {
      id: accountID = null,
      isTrialing = false,
      hasValidSubscription = false,
      mayTrialBeStarted = false,
    } = {},
    hasAccess: hasAccountAccess = [],
    emptyAccount
  } = useReactiveVar(selectedAccountVar) || {}
  const [
    startTrial,
    {
      error: failedStartingTrial, data, loading: statingTrial
    }
  ] = useMutation(START_TRIAL)
  useEffect(() => {
    if (!statingTrial && (data || failedStartingTrial)) {
      startTrialCallback()
    }
  }, [ failedStartingTrial, data, failedStartingTrial ])
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const messages = useRef<Messages>(null)
  const toast = useRef<Toast>(null)
  const { showTopBanner = false, updateDashboard } = useContext(DashboardContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [ trackEventInSegment ] = getSegmentTrack()

  const canExtendTrial = (): boolean => hasAccountAccess.some((policy: any) => (policy === Policy.AccountManagement || policy === Policy.BillingManagement))

  const canUpgrade = (): boolean => hasAccountAccess.some((policy: any) => (policy === Policy.AccountManagement))

  const isOnBillingPage = isValid(matchPath(Navigation.AccountSettingBilling, `${location.pathname}${location.search}`))
  const hasValidSubscriptionOrInTrial = () => (isTrialing || hasValidSubscription)

  const startTrialCallback = () => {
    try {
      if (failedStartingTrial) {
        throw failedStartingTrial
      } else if (data && data.startTrial) {
        trackEventInSegment(TrackEventType.TRIAL_EXTENDED)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.successDetail', { context: 'extendTrial' }),
          life: 3000
        })

        setTimeout(() => {
          window.location.href = Navigation.Account
        }, 1000)
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed starting trial', error?.graphQLErrors)
    }
  }

  const extendTrial = () => {
    accountID && startTrial({ variables: { accountID } })
  }

  const upgradeNow = () => {
    navigate(Navigation.AccountSettingBilling)
  }

  useEffect(() => {
    if (!hasValidSubscriptionOrInTrial() && hasAccountAccess.length > 0) {
      updateDashboard({ showTopBanner: true })
      const severity = mayTrialBeStarted ? 'info' : 'error'
      setTimeout(() => {
        messages.current?.replace({
          severity,
          sticky: true,
          summary: <TrialExtendBanner {...{
            upgradeOnly: !mayTrialBeStarted, isOnBillingPage, extendTrial, upgradeNow, canExtendTrial, canUpgrade
          }}
          />
        })
      })
    }
  }, [ isOnBillingPage, hasAccountAccess, accountID ])

  if (hasValidSubscriptionOrInTrial() || emptyAccount) {
    return <></>
  }

  return (
    <TrialExtendTimeWrapper showBanner={showTopBanner}>
      { showTopBanner && <Messages data-testid="start-trial-message" ref={messages} onRemove={() => updateDashboard({ showTopBanner: false })} />}

      <Toast data-testid="start-trial-status" ref={toast} position="top-right" />
    </TrialExtendTimeWrapper>
  )
}
